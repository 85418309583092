import React from 'react';
import styled from 'styled-components';
import PText from './PText';

const AboutItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* gap: 5rem; */
  margin-top: 3rem;
  .title {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary};
  }
  .items {
    display: flex;
    gap: 1.5rem;
    position: absolute;
    left: 18rem;
  }
  .item {
    background-color: ${({ theme }) => theme.colors.fifth};
    padding: 1rem;
    border-radius: 8px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    .items {
      position: initial;
      gap: 1rem;
    }
    .title {
      font-size: 2rem;
      margin-right: 50px;
    }
  }
`;

export default function AboutInfoItem({
    title = 'Title',
    items = ['HTML', 'CSS'],
}) {
    return (
        <AboutItemStyles>
            <h1 className="title">{title}</h1>          
            <div className="items">
                {items.map((item, index) => (
                    <div className="item" key={index}>
                        <PText>{item}</PText>
                    </div>
                ))}
            </div>
        </AboutItemStyles>
    );
}