import React from 'react';
import { MdPlace } from 'react-icons/md';
import styled from 'styled-components';
import PText from './PText';

const ItemStyles = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.fifth};
  display: flex;
  align-items: center;
  gap: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  .icon {
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.fourth};
    padding: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  svg {
    width: 3.5rem;
  }
`;

export default function ContactInfoItem({
    icon = <MdPlace />,
    text = 'I need text ',
    link 
}) {
    return (
        <ItemStyles>
            <div className="icon">{icon}</div>
            <div className="info">
                <a href={link} target='_blank' rel="noopener noreferrer">
                    <PText>{text}</PText>
                </a>
            </div>
        </ItemStyles>
    );
}